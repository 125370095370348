import styled, { css } from 'styled-components';
import Heading from 'components/shared/Heading';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 32px 0;
  margin-top: 85px;
  max-width: 700px;
  line-height: 1.3;

  ${({ theme }) => theme.mq.lg} {
    padding: 72px 0;
    margin-top: 100px;
  }

  @media (min-width: 1920px) {
    max-width: 840px;
    padding: 96px 0;
  }
`;

export const Header = styled.header`
  h1 {
    margin-bottom: 16px;
  }
  margin-bottom: 40px;
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: 16px;
`;

const privacyPolicyCss = css`
  p {
    margin-bottom: 12px;

    &.list {
      padding-left: 20px;

      span {
        margin-left: -20px;
        margin-right: 8px;
      }
    }
  }

  strong {
    font-weight: 700;
    margin-bottom: 8px;
    display: block;
  }
`;

const cookiesCss = css`
  display: flex;
  flex-direction: column;

  h1,
  h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  h1 {
    text-align: center;
  }

  b {
    font-weight: 700;
  }

  p {
    margin-left: 16px;

    &.list {
      span {
        margin-right: 8px;
      }
    }
  }

  a {
    color: blue;
  }

  .underline {
    text-decoration: underline;
    font-weight: 700;
    margin-bottom: 6px;
    margin-top: 20px;
    margin-left: 16px;
  }

  .orange {
    color: #f47920;
    text-decoration: underline;
    font-weight: 700;
    margin-left: 16px;
  }
`;

export const StyledContent = styled.div`
  font-size: 1.6rem;
  strong {
    font-weight: 700;
    margin-bottom: 20px;
    display: block;
  }
  a {
    color: blue;
  }

  ${({ $isCookiesPolicy }) =>
    !$isCookiesPolicy &&
    css`
      ${privacyPolicyCss}
    `}

  ${({ $isCookiesPolicy }) =>
    $isCookiesPolicy &&
    css`
      ${cookiesCss}
    `}
`;
