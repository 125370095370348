import React, { useEffect } from 'react';

import Content from 'components/shared/Content';

import {
  Wrapper,
  Header,
  StyledContent,
  StyledHeading,
} from './PrivacyPolicy.style';

export const PrivacyPolicy = ({ heading, content, isCookiesPolicy }) => {
  useEffect(() => {
    // Add target="_blank" to all links in the content

    const contentElement = document.getElementById('content');
    const links = contentElement?.querySelectorAll('a');

    if (links) {
      links.forEach(link => {
        if (link.href.includes('mailto')) {
          return;
        }

        link.setAttribute('target', '_blank');
      });
    }
  }, []);

  return (
    <Content>
      <Wrapper>
        <Header>
          <StyledHeading
            as="h1"
            dangerouslySetInnerHTML={{
              __html: heading,
            }}
          />
        </Header>
        <StyledContent
          id="content"
          dangerouslySetInnerHTML={{ __html: content }}
          $isCookiesPolicy={isCookiesPolicy}
        />
      </Wrapper>
    </Content>
  );
};
